export const LANDING_TITLE = "A Value-based Care Dashboard";
export const LANDING_WELCOME = "Welcome, to Your wellPORTAL";
export const LANDING_ACCOUNT = "Our records indicate that this account is associated with, ";
export const LANDING_CALL_TO_ACTION = "Where would you like to go ?";

export const MSG_FETCHING_DATA = "Fetching Data";
export const MSG_GENERATING_DATA =
  "We are working on generating data for your request. Please try again shortly.";
export const MSG_NETWORK_ERROR = "Hmm. It looks like there was a problem completing your request.";
export const MSG_AUTH_ERROR_NO_TOKEN = "Sorry, you do not have authorization to view this page.";
export const MSG_AUTH_ERROR_TOKEN = "Sorry, you are unauthorized to view this page.";

export const ESTABLISHED_SWITCH = "View Established Patients Only";
export const ESTABLISHED_SWITCH_CAPTION =
  "Established patients have completed at least one visit with a wellPORTAL PCP in the last 15 months.";

export const COHORT_1 = "On YOUR wellPORTAL";
export const COHORT_2 = "Not On YOUR wellPORTAL";
export const COHORT_3 = "All Patients";

export const COHORT_1_SHORT = "OnYwP";
export const COHORT_2_SHORT = "Not OnYwP";
export const COHORT_3_SHORT = "All";
export const COHORT_1_COLOR = "#001269";
export const COHORT_2_COLOR = "#62717a";
export const COHORT_3_COLOR = "#26b6fa";
export const TARGET_GREEN = "#1de9b6";

export const GRAPH_QM_TOOLTIP = "View your scores over time";
export const GRAPH_COPY = " patient quality measures satisfied";

export const PCP_GROUPS = [
  "All PCP Groups",
  "Mountain View Primary Care",
  "Las Vegas Medical Group",
  "Osteopathic Medical Associates Of Nevada",
  "P3 Medical Group",
  "Southern Nevada Internal Medicine & Pediatrics",
  "Calderon Medical Group",
  "Internal Medicine of Spring Valley",
  "Forte Family Practice",
  "Ace Primary Care",
  "Sundance Medical Center",
  "Diagnostic Center of Medicine",
  "Aguila Medical Group"
];

export const PCP_GROUPS_PHP = [
  "All PCP Groups",
  "Mountain View Primary Care",
  "Las Vegas Medical Group",
  "Osteopathic Medical Associates Of Nevada",
  "P3 Medical Group",
  "Southern Nevada Internal Medicine & Pediatrics",
  "Calderon Medical Group",
  "Forte Family Practice",
  "Ace Primary Care",
  "Sundance Medical Center",
  "Diagnostic Center of Medicine",
  "Aguila Medical Group"
];

export const PCP_GROUPS_WYNN = [
  "All PCP Groups",
  "Mountain View Primary Care",
  "Osteopathic Medical Associates Of Nevada",
  "P3 Medical Group",
  "Southern Nevada Internal Medicine & Pediatrics",
  "Calderon Medical Group",
  "Forte Family Practice",
  "Ace Primary Care",
  "Sundance Medical Center",
  "Diagnostic Center of Medicine",
  "Aguila Medical Group"
];

export const QM_ORDER = [
  "composite",
  "Annual Wellness Visit",
  "Cholesterol Management - Total Cholesterol",
  "Cholesterol Management - LDL Cholesterol",
  "Depression Management",
  "Diabetes Management - Blood Pressure Control",
  "Diabetes Management - A1C Control",
  "Diabetes Management - A1C Control",
  "Diabetes Management - Kidney Function Test",
  "Diabetes Management - Diabetic Eye Exam",
  "Hypertension - Blood Pressure Control",
  "Statin Therapy",
  "Breast Cancer Screening",
  "Colon Cancer Screening",
];
export const HEALTH_PLANS = ["Prominence Health Plan", "NV Energy", "Greenspun Media Group", "Wynn Resorts Health Plan"];
export const REWARDS_HEALTH_PLANS = ["Prominence Health Plan"];
export const PHP_REWARDS_ORDER = [
  "Prominence Rewards - Creating a Your wellPORTAL Account",
  "Prominence Rewards - Establishing Care with Your PCP",
  "Prominence Rewards - Annual Wellness Visit",
  "Prominence Rewards - Required Lab Draw",
  "Prominence Rewards - Required Preventive Service",
  "Prominence Rewards - Responding to Notifications",
];

export const PERIODS = [2024, 2023, 2022, 2021];
export const PE_PERIODS = [2024, 2023, 2022, 2021, 2020];
export const REWARDS_PERIODS = ["Cumulative", 2024, 2023, 2022, 2021, 2020];
export const QM_COMPOSITE_COPY =
  "The composite quality measure score combines all the individual quality measures to produce one result that gives a more complete picture of quality measure performance.";

export const PEM_COMPOSITE_COPY =
  "The composite patient experience score combines several individual patient experience measures to produce one result that gives a more complete picture of patient experience performance.";

export const SURVEY_TYPES = [
  { survey_subtype: "telehealth", survey_type: "quality_of_care" },
  { survey_subtype: "clinic", survey_type: "customer_service" },
  { survey_subtype: "clinic", survey_type: "quality_of_care" },
];

export const REPORTS = [
  { report_type: "survey_results", survey_type: "customer_service", survey_subtype: "clinic" },
  {
    report_type: "survey_response_rate",
    survey_type: "customer_service",
    survey_subtype: "clinic",
  },
  { report_type: "survey_results", survey_type: "quality_of_care", survey_subtype: "clinic" },
  { report_type: "survey_response_rate", survey_type: "quality_of_care", survey_subtype: "clinic" },
  { report_type: "nps", survey_type: "customer_service", survey_subtype: "clinic" },
  { report_type: "nps", survey_type: "quality_of_care", survey_subtype: "clinic" },
  { report_type: "survey_results", survey_type: "customer_service", survey_subtype: "telehealth" },
  {
    report_type: "survey_response_rate",
    survey_type: "customer_service",
    survey_subtype: "telehealth",
  },
  { report_type: "survey_results", survey_type: "quality_of_care", survey_subtype: "telehealth" },
  {
    report_type: "survey_response_rate",
    survey_type: "quality_of_care",
    survey_subtype: "telehealth",
  },
  { report_type: "nps", survey_type: "quality_of_care", survey_subtype: "telehealth" },
];

export const PCP_COMPOSITES_BARGRAPH_OPTIONS = {
  scale: {
    ticks: {
      maxTicksLimit: 30,
    },
  },
  indexAxis: "y",
  responsive: true,
  plugins: {
    annotation: {
      annotations: {
        line1: {
          type: "line",
          scaleID: "x",
          borderWidth: 2,
          borderColor: TARGET_GREEN,
          value: 85,
          label: {
            font: {
              weight: "light",
            },
            rotation: "auto",
            enabled: true,
          },
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        font: {
          size: 12,
          weight: "light",
        },
      },
    },
  },
};

export const VERTICAL_BARGRAPH_OPTIONS = {
  scale: {
    ticks: {
      maxTicksLimit: 5,
    },
  },

  responsive: true,
  plugins: {
    annotation: {
      annotations: {
        line1: {
          type: "line",
          scaleID: "y",
          borderWidth: 2,
          borderColor: TARGET_GREEN,
          value: 85,
          label: {
            font: {
              weight: "light",
            },
            rotation: "auto",
            enabled: true,
          },
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        font: {
          size: 12,
          weight: "light",
        },
      },
    },
  },
};
export const HORIZONTAL_BARGRAPH_OPTIONS = {
  scale: {
    ticks: {
      maxTicksLimit: 5,
    },
  },
  indexAxis: "y",
  responsive: true,
  plugins: {
    annotation: {
      annotations: {
        line1: {
          type: "line",
          scaleID: "x",
          borderWidth: 2,
          borderColor: TARGET_GREEN,
          value: 85,
          label: {
            font: {
              weight: "light",
            },
            rotation: "auto",
            enabled: true,
          },
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        usePointStyle: true,
        font: {
          size: 12,
          weight: "light",
        },
      },
    },
  },
};

let annotations = {
  plugins: {
    annotation: {
      annotations: {
        line1: {
          type: "line",
          scaleID: "y",
          borderWidth: 2,
          borderColor: TARGET_GREEN,
          value: 85,
          label: {
            font: {
              weight: "light",
            },
            rotation: "auto",
            enabled: true,
          },
        },
      },
    }
  }
}

let defaultTimegraphOptions = {
  scale: {
    ticks: {
      maxTicksLimit: 4,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },

  tooltip: {
    enabled: true,
    position: "nearest",
  },

  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
        font: {
          size: 12,
          weight: "light",
        },
      },
    }
  },

  responsive: true
}
export const DEFAULT_TIMELINEGRAPH_OPTIONS = {...defaultTimegraphOptions};
export const TIME_LINEGRAPH_OPTIONS = {...defaultTimegraphOptions, ...annotations};
