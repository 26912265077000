import React from "react";
import { Box } from "@material-ui/core";
import { Chart, Bar } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import * as CONST from "../../constants";

Chart.register([annotationPlugin]);

interface result {
  data: {
    eligible_patients: number;
    passing: number;
    failing: number;
    percent_passing: number;
  };
}
interface BarGraphProps {
  data: {
    current: {
      name: string;
      passing: any;
      results: {
        all_patients: result;
        without_account: result;
        with_account: result;
      };
    };
  };
}

export const BarGraph: React.FC<BarGraphProps> = ({ data }) => {
  const w = Math.round(data.current.results.with_account.data.percent_passing * 100);
  const wo = Math.round(data.current.results.without_account.data.percent_passing * 100);
  const all = Math.round(data.current.results.all_patients.data.percent_passing * 100);

  const getCohortData = (cohort, className) => {
    const value =
      cohort.passing +
      " out of " +
      cohort.eligible_patients +
      " patient quality measures satisfied";
    const score = Math.round(cohort.percent_passing * 100) + "%";
    return (
      <>
        <td id="right">
          <span id="value" className={className}>
            {score}
          </span>
        </td>
        <td id="left">
          <span id="td">{value}</span>
        </td>
      </>
    );
  };

  const renderBarTable = () => (
    <table id="qm">
      <thead>
        <tr>
          <th id="key"></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="key">
            <span id="key-dot" className="on-wP">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_1_SHORT}</td>
          {getCohortData(data.current.results.with_account.data, "on-wP")}
        </tr>
        <tr>
          <td id="key">
            <span id="key-dot" className="not-on-wP">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_2_SHORT}</td>

          {getCohortData(data.current.results.without_account.data, "not-on-wP")}
        </tr>
        <tr>
          <td id="key">
            {" "}
            <span id="key-dot" className="all">
              &#183;
            </span>
          </td>
          <td id="cohort">{CONST.COHORT_3}</td>
          {getCohortData(data.current.results.all_patients.data, "all")}
        </tr>
      </tbody>
    </table>
  );

  const renderBarGraph = () => (
    <Bar
      type="bar"
      id="bar"
      width={9}
      height={5}
      options={CONST.VERTICAL_BARGRAPH_OPTIONS}
      data={{
        labels: [""],
        datasets: [
          {
            stack: 1,
            label: CONST.COHORT_1_SHORT,
            data: [w],
            barThickness: 25,
            borderRadius: 20,
            hoverBorderWidth: 2,
            borderWidth: 2,
            borderColor: "white",
            fill: false,
            backgroundColor: CONST.COHORT_1_COLOR,
            hoverBorderColor: CONST.TARGET_GREEN,
          },
          {
            stack: 2,
            label: CONST.COHORT_2_SHORT,
            data: [wo],
            barThickness: 25,
            borderRadius: 20,
            hoverBorderWidth: 2,
            borderWidth: 2,
            borderColor: "white",
            fill: false,
            backgroundColor: [CONST.COHORT_2_COLOR],
            hoverBorderColor: CONST.TARGET_GREEN,
          },
          {
            stack: 3,
            label: CONST.COHORT_3_SHORT,
            data: [all],
            barThickness: 25,
            borderRadius: 20,
            hoverBorderWidth: 2,
            borderWidth: 2,
            borderColor: "white",
            fill: false,
            backgroundColor: [CONST.COHORT_3_COLOR],
            hoverBorderColor: CONST.TARGET_GREEN,
          },
        ],
      }}
    />
  );

  return (
    <>
      <Box id="graph-box">
        <label id="graph-box-label">Current Passing Scores</label>
        <p id="target-pill">{"TARGET 85%"}</p>{" "}
      </Box>
      {renderBarGraph()}
      {renderBarTable()}
    </>
  );
};
const styles = {} as const;
export default BarGraph;
